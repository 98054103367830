import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'
import { useGastroCardDynamicCurrentOffers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'

interface Props {
  rowClassName: string
}

export const GastroCardDynamicOffer = ({ rowClassName }: Props): React.ReactNode => {
  const { ref } = useScrollToOffer('podwojna-radosc-2025-plus')

  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.GastroCardDynamic)
  }

  const { offers } = useGastroCardDynamicCurrentOffers()

  if (!offers.length) return null

  return (
    <div className="container-lg" id="podwojna-radosc" ref={ref}>
      <OfferSection
        onImageClick={handleShowDetails}
        badges={
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
            <Badge
              variant="red"
              text="Oferta dostępna czasowo"
              className="mb-2 badge-pulsing col-12 col-sm-auto me-0 me-sm-3"
            />
          </div>
        }
        title={<span className="font-header">Podwójna Radość 2025 Plus</span>}
        description={
          <span>
            Skorzystaj ze specjalnej promocji na{' '}
            <strong className="text-nowrap">Voucher Podwójna Radość 2025 Plus</strong>{' '}
            <span className="text-nowrap">do wykorzystania</span> w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku
            podczas <span className="text-nowrap">pobytów w roku 2025 </span>{' '}
            <span className="text-nowrap">w Resortach Holiday Park & Resort.</span>
          </span>
        }
        onOrderClick={handleShowDetails}
        offerKind="gastro-card-dynamic"
        rowClassName={rowClassName}
      />
    </div>
  )
}
