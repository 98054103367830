import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { SnowfallContainer } from '@components/snowfall-container'

interface Props {
  rowClassName: string
}

export const ChristmasVoucherOffer = ({ rowClassName }: Props): React.ReactNode => {
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.ChristmasVoucher)
  }

  return (
    <div className="container-lg overflow-hidden">
      <SnowfallContainer>
        <OfferSection
          title={
            <span className="font-header">
              Świąteczne prezenty <br /> w Holiday
            </span>
          }
          description={
            <>
              <strong>Podaruj bliskim wakacje na Święta!</strong> <br /> Wybierz voucher na 3-dniowy pobyt{' '}
              <span className="text-nowrap">w Holiday Park & Resort.</span>
            </>
          }
          onOrderClick={handleShowDetails}
          onImageClick={handleShowDetails}
          offerKind="christmas-voucher"
          rowClassName={rowClassName}
        />
      </SnowfallContainer>
    </div>
  )
}
