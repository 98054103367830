import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { GastroCardCode } from '@models/app-state'
import { endOfDay, isBefore } from 'date-fns'

type Offer = 'christmas_voucher' | GastroCardCode

interface Response {
  isAvailable: (code: Offer) => boolean
}

export const useOfferAvailability = (): Response => {
  const appData = useAppSelector(selectAppData)

  const isAvailable = (code: Offer) => {
    if (code === 'christmas_voucher') {
      const CHRISTMAS_VOUCHER_END_DATE = endOfDay(new Date('2024-12-31'))
      return isBefore(new Date(), CHRISTMAS_VOUCHER_END_DATE)
    }

    if (code === 'gastro_card_dynamic') {
      const GASTRO_CARD_DYNAMIC_END_DATE = endOfDay(new Date('2024-12-31'))
      return isBefore(new Date(), GASTRO_CARD_DYNAMIC_END_DATE)
    }

    return appData.gastro_cards.some(gastroCard => gastroCard.code === code)
  }

  return {
    isAvailable,
  }
}
