import * as React from 'react'
import { SubscriptionWithBenefitOffer } from '@modules/offers/subscription-with-benefit/subscription-with-benefit-offer'
import { SubscriptionContractOffer } from '@modules/offers/subscription-contract'
import { CodesConversionOffer } from '@modules/offers/codes-conversion'
import { BookingOffers } from '@modules/booking-offers'
import { SubscriptionsOffer } from '@modules/offers/subscriptions'
import { EndlessHolidayOffer } from '@modules/offers/endless-holiday'
import { GastroCardBlackWeekOffer } from '@modules/offers/gastro-card/black-week'
import { useOfferAvailability } from '@modules/offers/use-offer-availability'
import { ChristmasVoucherOffer } from '@modules/offers/christmas-voucher'
import { GastroCardDynamicOffer } from '@modules/offers/gastro-card/dynamic'
import { GastroCardChristmasOffer } from '@modules/offers/gastro-card/christmas'

export const OffersSection = (): React.ReactNode => {
  const { isAvailable } = useOfferAvailability()

  const dynamicRowClassOffers = [
    ...(isAvailable('christmas_voucher') ? [ChristmasVoucherOffer] : []),
    ...(isAvailable('gastro_card_dynamic') ? [GastroCardDynamicOffer] : []),
    SubscriptionsOffer,
    CodesConversionOffer,
    SubscriptionContractOffer,
  ] as (({ rowClassName }) => JSX.Element)[]

  return (
    <>
      <SubscriptionWithBenefitOffer />
      <EndlessHolidayOffer />
      {isAvailable('gastro_card_black_week_2024') && <GastroCardBlackWeekOffer />}
      {isAvailable('gastro_card_christmas_2024') && <GastroCardChristmasOffer />}
      {dynamicRowClassOffers.map((Offer, index) => (
        <Offer key={index} rowClassName={index % 2 === 0 ? '' : 'flex-row-reverse'} />
      ))}

      <BookingOffers />
    </>
  )
}
